<template>
  <b-row>
    <b-col cols="12">
      <config-content />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ConfigContent from './ConfigContent.vue'

export default {
  components: {
    BRow,
    BCol,

    ConfigContent
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>